import { triggerSimpleAjax } from '../../../helpers/httpHelper';
import { CREATE_VIEW_MOBILE } from '../../../helpers/constants/apiUrl';

const createAutoAuthentication = (req = '' ,token = '') => {
    return new Promise((res, rej) => {
        const url = `${CREATE_VIEW_MOBILE}` + req + "/"; 

        const bodyData = {
           view_id: token
        };

        triggerSimpleAjax(
            url,
            'POST',
            bodyData, 
            (response) => {
                res(response);
            },
            (error) => {
                rej(error);
            }
        );
    });
};

export default createAutoAuthentication;