import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import RsLogoImage from './receipt_long.svg';
import Currency from './currency_rupee.svg';
import Crisis from './crisis_alert.svg';
import Article from './article.svg';
import LocalActivity from './program.svg';
import AssuredWorkLoad from './assured_workload.svg';
import Group from './group_176.svg';
import Logo from './logo.svg';
import EzeepayLogo from './image_9.svg';
import getMobileView from '../../../actions/creation/getMobileView';
import { DASHBOARDROUTES } from '../../../helpers/constants/routes';
import createAutoAuthentication from './createAuthentication';
import JSEncrypt from "node-jsencrypt";
import { PUBLIC_RSA_KEY } from '../../../../src/app-config'

const Container = styled.div`
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
`;

const Header = styled.header`
  background-color: #fff;
  padding: 10px 0;

  img {
    max-width: 100px; /* Adjust as needed */
    height: auto; /* Maintain aspect ratio */
  }
`;

const ProgramInfo = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  border-bottom: 5px solid #888888;
  text-align: left;
  width: 100%;
  max-width: 500px;
  background: #f8f8f8;
  margin-top: 20px;
  font-size: 16px; 

  @media (max-width: 768px) {
    padding: 8px;
    border-radius: 4px;
    max-width: 100%;
    font-size: 14px; 
  }
  @media (max-width: 480px) {
    padding: 6px;
    border-radius: 3px;
    width: 100%;
    margin-top: 20px;
    font-size: 12px; 
  }
`;

const KycStatus = styled.div`
  margin-top: 10px;
  font-size: 10px;
`;

const Greeting = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left; 
  margin-top: 10px; 
  font-size: 16px; 
`;

const MainMenu = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 19px;
  margin: 29px 0;

  @media (max-width: 600px) {
    gap: 19px;
  }
`;

const MenuItem = styled.div`
  width: 155px;
  height: 90px;
  border: 1px solid #2e487b;
  box-shadow: 5px 5px #2e487b;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #f8f8f8;
  box-sizing: border-box;
  flex: 1 0 calc(50% - 10px);
  max-width: calc(50% - 10px);
  padding: 10px;

  @media (max-width: 600px) {
    padding: 6px;
  }

  img {
    width: 30px;
    height: auto;
    margin-bottom: 8px;
  }

  div {
    font-size: 16px;
    text-align: left;
  }
`;

const TopFooter = styled.footer`
  margin-top: 20px;

  img {
    max-width: 100%; 
    height: auto;
  }
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  div {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #2e487b;
  }

  img {
    margin-left: 5px;
    width: 20px;
    height: auto;
  }
`;

const MenuItemText = styled.div`
  margin-top: 5px;
  font-size: 16px;
`;

const DesktopMessage = styled.div`
  color: red;
  font-size: 1.2em;
  margin-bottom: 20px;
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 100%;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const ModalText = styled.span`
  font-family: 'Barlow', sans-serif;
  font-size: 10px;
  margin-bottom: 10px;
`;

const EzeepayLogoImage = styled.img`
  width: 50px;
  cursor: pointer;
`;

const TopFooterImage = styled.img`
  cursor: pointer;
  width: 500px; 
  height: auto; 
`;

const MobileView = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isKycModalOpen, setIsKycModalOpen] = useState(false);
  const [mobileViewDetails, setMobileViewDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth > 600);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize); 
  }, []);

  const req = location.pathname.split('slug')[1];

  useEffect(() => {
    const autoAuthentication = async () => {
      try {
        let existingToken = localStorage.getItem('token');

        // If Local Storage is Empty or NULL
        if(existingToken === null || existingToken === "null"|| existingToken === '') {
            const now = new Date();
            const formattedDate = `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}`;
            const generatedToken = formattedDate;  
            existingToken = encrypt_password(generatedToken);
        }
        
        localStorage.setItem('token', existingToken);

        // API Integration
        const response = await createAutoAuthentication(req, existingToken);

        if (response && response.results) {
          setIsAuthenticated(true); 
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Error fetching requests:", error); 
      }
    };

    autoAuthentication();
  }, [isAuthenticated]);

  useEffect(() => {
    getMobileView(req)
      .then((response) => {
        const { results } = response;
        setMobileViewDetails(results[0] || {});
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }, [req]);



  const handleForm16Click = () => {
    setIsModalOpen(true);
  };

  const encrypt_password = (string_to_encrypt) => {
      let encrypt = new JSEncrypt();
      encrypt.setPublicKey(PUBLIC_RSA_KEY);
      return encrypt.encrypt(string_to_encrypt);
};
  const handleKycDetailsClick = () => {
    setIsKycModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsKycModalOpen(false);
  };

  const openEzeepay = () => {
    window.location.href =
      'https://retailscanportal.com/ezeepay/deeplink';
  };

  const handleFooterClick = () => {
    window.location.href = "https://retail-scan.hesk.com/index.php?a=add&category=6";
  };

  return (
    <Container>
      {isDesktop ? (
        <DesktopMessage>Please switch to mobile view for a better experience</DesktopMessage>
      ) : isAuthenticated ? (       
        <>
          <Header>
            <img src={mobileViewDetails.project_organisation_logo_image} alt="Logo" />
          </Header>
          <Greeting>
            Hello {mobileViewDetails.name} <span role="img" aria-label="wave">👋</span>
          </Greeting>
          <ProgramInfo>
            <div style={{ fontSize: '10px' }}>
              <img src={LocalActivity} style={{ marginBottom: '-3px', marginRight: '8px' }} />
              Program: {mobileViewDetails.program_name}
            </div>
            <KycStatus>
              <img src={AssuredWorkLoad} style={{ marginBottom: '-3px', marginRight: '8px' }} />
              KYC Status: {mobileViewDetails.is_kyc_successful ? 'Successful ✅' : 'Unsuccessful'}
            </KycStatus>
            <a onClick={handleKycDetailsClick} style={{ fontSize: '8px', marginTop: '18px', display: 'inline-block', color: '#2E487B' }}>
              View KYC Details
            </a>
          </ProgramInfo>
          
          <p style={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', color: '#2E487B' }}>Check Your</p>
          <MainMenu style={{ filter: isModalOpen || isKycModalOpen ? 'blur(5px)' : 'none' }}>
            <MenuItem as={Link} to={`${DASHBOARDROUTES.EARNING_MOBILE_VIEW}${req}`}>
              <img src={Currency} alt="Earnings" />
              <MenuItemText>Earnings</MenuItemText>
            </MenuItem>
            <MenuItem as={Link} to={`${DASHBOARDROUTES.INVOICE_MOBILE_VIEW}${req}`}>
              <img src={RsLogoImage} alt="Invoices" />
              <MenuItemText>Invoices</MenuItemText>
            </MenuItem>
            <MenuItem as={Link} to={`${DASHBOARDROUTES.TARGET_MOBILE_VIEW}${req}`}>
              <img src={Crisis} alt="Target vs Ach" />
              <MenuItemText>Target vs Ach</MenuItemText>
            </MenuItem>
            <MenuItem onClick={handleForm16Click}>
              <img src={Article} alt="Form-16" />
              <MenuItemText>Form - 16</MenuItemText>
            </MenuItem>
          </MainMenu>
          <TopFooter>
            <TopFooterImage src={Group} alt="Footer Group" onClick={handleFooterClick} />

          </TopFooter>
          <Footer>
            <div>
              <p style={{ width: '90px', height: '20px', fontWeight: 'bold' }}>Powered by</p>
              <img src={Logo} alt="Footer Group" />
            </div>
          </Footer>
          {(isModalOpen || isKycModalOpen) && (
            <ModalBackground onClick={closeModal}>
              <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>
                  <ModalText>Open With</ModalText>
                  <EzeepayLogoImage onClick={openEzeepay} src={EzeepayLogo} alt="Ezeepay Logo" />
                </ModalHeader>
              </ModalContent>
            </ModalBackground>
          )}
        </>
      ) : (
        <DesktopMessage>
          Seems like you have opened the forwarded link.
          <p>The link is not Accessible. Please contact Retail Scan Team.</p></DesktopMessage>
      )}
    </Container>
  );
};

export default MobileView;
